<template>
	<div class="home">
		<top-nav @menu_data="getMenuData" :isARClogo="isARClogo" />
		<div class="part0">
			<div class="part0Top">
				<div class="background">
					<img class="part0BackImg" src="../../assets/imgs/app/marketplace/backg1.png"
						style="width: 1093px;height: 399px;">
				</div>

				<div class="part0Cont">
					<img class="part0Img" src="../../assets/imgs/app/marketplace/pic1.png">
				</div>
			</div>
			<div class="part0Bottom">
				<div class="part0BotTitle">
					Project Marketplace
				</div>
				<div class="part0BotContent">
					Off the plan sales opportunities await with Arcanite’s exciting networking marketplace. Whether
					you’re looking to incorporate project sales, or already sell properties off the plan, Project
					Marketplace has new and upcoming developments waiting for agents to sell.
				</div>
				<div class="part0BotButton">
					<a v-if="!isID" href=" "
						onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      return false;">
						<div class="part0ButtonText">Book A Demo<img src="./../../assets/imgs/app/arcanite/vector.png"
								class="vector"></div>
					</a>
					<a v-else href=" "
						onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
      return false;">
						<div class="part0ButtonText">Book A Demo<img src="./../../assets/imgs/app/arcanite/vector.png"
								class="vector"></div>
					</a>
				</div>
			</div>
		</div>
		<div class="part1">
			<div class="part1Total">
				<div class="part1Top">
					<div class="part1TopTitle">
						Build your professional network
					</div>
					<div class="part1TopContent">
						Connect with others looking to increase business opportunities through newfound sales channels
					</div>
				</div>
				<div class="part1Bottom">
					<div class="items">
						<div class="item topItem" :class="{item1Hover: item1Active}">
							<div class="itemPic1">
								<lottie :options="defaultOptions.property"
									@animCreated="handleAnimation($event,'property')" :speed="0.5" />
							</div>
							<!-- <img class="part1_1" src="./../../assets/imgs/pc/marketplace/part2_1.png"> -->
							<div class="itemTitle">
								More properties
							</div>
							<div class="itemContent">
								Diversify your property offerings
							</div>
						</div>
						<div class="item" :class="{item2Hover: item2Active}">
							<!-- <div class="itemImg">
								<img class="part1_2" src="./../../assets/imgs/pc/marketplace/part2_2.png">
							</div> -->
							<div class="itemPic2">
								<lottie :options="defaultOptions.developer"
									@animCreated="handleAnimation($event, 'developer')" :speed="0.5" />
							</div>
							<div class="itemTitle">
								More developers
							</div>
							<div class="itemContent">
								Network with property developers
							</div>
						</div>
						<div class="item" :class="{item3Hover: item3Active}">
							<!-- <div class="itemImg">
								<img class="part1_3" src="./../../assets/imgs/pc/marketplace/part2_3.png">
							</div> -->
							<div class="itemPic3">
								<lottie :options="defaultOptions.opportunity"
									@animCreated="handleAnimation($event, 'opportunity')" :speed="0.5" />
							</div>
							<div class="itemTitle">
								More opportunities
							</div>
							<div class="itemContent">
								Gain access to projects to sell
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div v-for="i,index in pageContent" :key="'part2'+index"> -->
		<div class="part2">
			<div class="part2_1">
				<div class="part2_1Top">
					<div class="image1">
						<lottie :options="defaultOptions.project" @animCreated="handleAnimation($event, 'project')"
							:speed="1" />
					</div>
				</div>
				<div class="part2_1Bottom rightContent">
					<div class="p1">
						<img src="./../../assets/imgs/pc/marketplace/icon1.png" class="pic" />
						<p>Pick your projects</p>
						<div class="content">
							Connect with property developers who have upcoming projects in your area
						</div>
						<div class="line"></div>
						<div class="content">
							<div class="fullyLine">
								<div class="perLine">
									<div class="dog"></div>
									<div class="text">Choose who you connect with</div><br>
								</div>
								<div class="perLine">
									<div class="dog" style=""></div>
									<div class="text">Discover upcoming off the plan projects near you</div><br>
								</div>
								<div class="perLine">
									<div class="dog"></div>
									<div class="text">Select projects that suit your buyers</div><br>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="part2_2">
				<!-- <div class="part2_Image2">
					<lottie :options="defaultOptions.way"
						@animCreated="handleAnimation($event, 'way')" :speed="1" />
				</div> -->
				<div class="part2_2Top">
					<div class="image2">
						<lottie :options="defaultOptions.way" @animCreated="handleAnimation($event, 'way')"
							:speed="1" />
					</div>
				</div>
				<div class="part2_2Bottom rightContent">
					<img class="circleBack" src="../../assets/imgs/app/marketplace/circleBack.png"
						style="width: 358px;height: 358px;">
					<div class="p1">
						<img src="./../../assets/imgs/pc/marketplace/icon2.png" class="pic" />
						<p>Your marketplace your way</p>
						<div class="content">
							Choose how many connections you engage with and which projects interest you
						</div>
						<div class="line"></div>
						<div class="content">
							<div class="fullyLine">
								<div class="perLine">
									<div class="dog"></div>
									<div class="text">Make strategic connections</div><br>
								</div>
								<div class="perLine">
									<div class="dog dog1"></div>
									<div class="text">Utilise marketplace as often as you choose</div><br>
								</div>
								<div class="perLine">
									<div class="dog"></div>
									<div class="text">Designed for Arcanite members and run by Arcanite members</div>
									<br>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="part2_3">
				<div class="part2_3Top">
					<!-- <img src="../../assets/imgs/app/marketplace/image3.png" class="image3"
						style="width: 324px;height: 293px;"> -->
					<div class="image3">
						<lottie :options="defaultOptions.revenue" @animCreated="handleAnimation($event, 'revenue')"
							:speed="1" />
					</div>
				</div>
				<div class="part2_3Bottom rightContent">
					<div class="p1">
						<img src="./../../assets/imgs/pc/marketplace/icon3.png" class="pic" />
						<p>Increase your sales revenue</p>
						<div class="content">
							With a marketplace full of properties to sell the opportunities are endless
						</div>
						<div class="line"></div>
						<div class="content">
							<div class="fullyLine">
								<div class="perLine">
									<div class="dog"></div>
									<div class="text">Always have projects available to sell</div><br>
								</div>
								<div class="perLine">
									<div class="dog"></div>
									<div class="text">No time spent outsourcing stock</div><br>
								</div>
								<div class="perLine">
									<div class="dog"></div>
									<div class="text">Sell effortlessly through your Arcanite app</div><br>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<other-product></other-product>
		<footer-nav />
		<menu-bar :isShow="isShow" @changeIsShow="changeShow" />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isID:false,
				scrollTop: 0,
				defaultOptions: {
					property: {
						animationData: require('@/assets/imgs/pc/marketplace/moreProperties.json'),
						autoplay: true,
						loop: false,
					},
					developer: {
						animationData: require('@/assets/imgs/pc/marketplace/moreDevelopers.json'),
						autoplay: true,
						loop: false,
					},
					opportunity: {
						animationData: require('@/assets/imgs/pc/marketplace/moreOpportunities.json'),
						autoplay: true,
						loop: false,
					},
					project: {
						animationData: require('@/assets/imgs/pc/marketplace/pickYourProjects.json'),
						autoplay: false,
						loop: true,
					},
					way: {
						animationData: require('@/assets/imgs/pc/marketplace/YourMarketplaceYourWay.json'),
						autoplay: false,
						loop: true,
					},
					revenue: {
						animationData: require('@/assets/imgs/pc/marketplace/IncreaseYourSalesRevenue.json'),
						autoplay: false,
						loop: true,
					},
				},
				animationPlay: true,
				anim: {},
				clientHeight: 0,
				WinPosition: 0,
				anima1Pos: 0,
				anima2Pos: 0,
				anima3Pos: 0,
				anima4Pos: 0,
				anima5Pos: 0,
				anima6Pos: 0,
				item1Active: false,
				item2Active: false,
				item3Active: false,
				anima4IsActive:true,
				anima5IsActive:true,
				anima6IsActive:true,
				anima1IsActive:true,
				anima2IsActive:true,
				anima3IsActive:true,
			}
		},
		created() {
			if(localStorage.getItem('country')=="id"){
				this.isID=true;
			}else{
				this.isID=false;
			}
			window.addEventListener("scroll", this.handleScroll, true);
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll, true)
		},
		methods: {
			handleScroll(el) {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				this.clientHeight = `${document.documentElement.clientHeight}`
				this.WinPosition = scrollTop + this.clientHeight * 0.7
				// console.log("窗口位置", scrollTop + this.clientHeight * 0.7)
				// console.log("标签位置", parseInt(this.$el.querySelector('.itemPic1').offsetTop))
				this.anima1Pos = parseInt(this.$el.querySelector('.itemPic1').offsetTop)
				this.anima2Pos = parseInt(this.$el.querySelector('.itemPic2').offsetTop)
				this.anima3Pos = parseInt(this.$el.querySelector('.itemPic3').offsetTop)
				this.anima4Pos = parseInt(this.$el.querySelector('.image1').offsetTop)
				this.anima5Pos = parseInt(this.$el.querySelector('.part2_2').offsetTop)
				this.anima6Pos = parseInt(this.$el.querySelector('.image3').offsetTop)

				if (this.WinPosition > this.anima1Pos && this.WinPosition < this.anima1Pos + this.clientHeight * 0.5) {
					this.item1Active = true;
					this.item2Active = false;
					this.item3Active = false;
					this.anima2IsActive = true;
					this.anima3IsActive = true;
					if(this.WinPosition > this.anima1Pos && this.anima1IsActive){
						this.anim['property'].setSpeed(0.5);
						this.anim['property'].playSegments([0, this.anim['property'].totalFrames], true);
						this.anima1IsActive = false;
					}
					else{
						
					}
				} else if (this.WinPosition > this.anima2Pos && this.WinPosition < this.anima2Pos + this.clientHeight *
					0.5) {
					this.item1Active = false;
					this.item2Active = true;
					this.item3Active = false;
					this.anima1IsActive = true;
					this.anima3IsActive = true;
					if(this.WinPosition > this.anima2Pos && this.anima2IsActive){
						this.anim['developer'].setSpeed(0.5);
						this.anim['developer'].playSegments([0, this.anim['developer'].totalFrames], true);
						this.anima2IsActive = false;
					}
					else{
						
					}
				} else if (this.WinPosition > this.anima3Pos && this.WinPosition < this.anima3Pos + this.clientHeight *
					0.5) {
					this.item1Active = false;
					this.item2Active = false;
					this.item3Active = true;
					this.anima1IsActive = true;
					this.anima2IsActive = true;
					if(this.WinPosition > this.anima3Pos && this.anima3IsActive){
						this.anim['opportunity'].setSpeed(0.5);
						this.anim['opportunity'].playSegments([0, this.anim['opportunity'].totalFrames], true);
						this.anima3IsActive = false;
					}
					else{
						
					}
				} else if (this.WinPosition > this.anima4Pos && this.WinPosition < this.anima4Pos + this.clientHeight *
					0.5 && this.anima4IsActive) {
					this.item1Active = false;
					this.item2Active = false;
					this.item3Active = false;
					this.anima3IsActive = true;
					this.anima4IsActive = false;
					this.anim['project'].playSegments([0, this.anim['project'].totalFrames], true);
				} else if (this.WinPosition > this.anima5Pos && this.WinPosition < this.anima5Pos + this.clientHeight *
					0.5 && this.anima5IsActive) {
					this.item1Active = false;
					this.item2Active = false;
					this.item3Active = false;
					this.anima5IsActive = false;
					this.anim['way'].playSegments([0, this.anim['way'].totalFrames], true);
				} else if (this.WinPosition > this.anima6Pos && this.WinPosition < this.anima6Pos + this.clientHeight *
					0.5 && this.anima6IsActive) {
					this.item1Active = false;
					this.item2Active = false;
					this.item3Active = false;
					this.anima6IsActive = false;
					this.anim['revenue'].playSegments([0, this.anim['revenue'].totalFrames], true);
				} else {
					this.item1Active = false;
					this.item2Active = false;
					this.item3Active = false;
				}
				// if (scrollTop+clientHeight/2>parseInt(this.$el.querySelector('.itemTitle').offsetTop)){
				// 	playStart('property');
				// }
				// return top <= viewPortHeight + 100
			},
			handleAnimation(event, animationName) {
				this.anim[animationName] = event;
			},
			playStart(animationName) {
				// this.anim.setSpeed(0.5);
				this.anim[animationName].playSegments([0, this.anim[animationName].totalFrames], true);

			},
			playEnd(animationName) {
				this.anim[animationName].goToAndStop(this.anim[animationName].totalFrames - 1, true);
			},
		},
		components: {
			"top-nav": (resolve) => require(["./TopNav.vue"], resolve),
			"footer-nav": (resolve) => require(["./FooterNav.vue"], resolve),
			"menu-bar": (resolve) => require(["./MenuBar.vue"], resolve),
			"other-product": (resolve) => require(["./OtherProduct.vue"], resolve),
		},
	}
</script>

<style lang="scss" scoped>
	.home {
		text-align: center;
		display: block;
		width: 100%;
		margin: auto;
		max-width: 428px;
		min-width: 320px;
		overflow-x: hidden;
	}

	.part0 {
		position: relative;
		height: 100%;
		width: 100%;

		.part0Top {
			position: relative;
			height: 450px;
			margin-bottom: 55px;

			.part0BackImg {
				top: 30px;
				position: relative;
				// margin: 0 auto;
				max-width: 428px;
				min-width: 320px;
				z-index: 1;
			}


			.part0Cont {
				// height: 100%;

				.part0Img {
					position: absolute;
					width: 100%;
					max-width: 428px;
					left: -1.49px;
					top: 130px;
					z-index: 1;
				}
			}
		}

		.part0Bottom {
			margin: 0 26px;
			// margin-top: 40px;
			padding: 0px;
			width: auto;
			height: 258px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			.part0BotTitle {
				// margin-left: 10px;
				width: 323px;
				height: 36px;
				font-family: 'Poppins-Bold';
				font-style: normal;
				// font-weight: 700;
				font-size: 30px;
				line-height: 36px;
				display: flex;
				align-items: center;
				color: #062440;
				flex: none;
				order: 0;
				flex-grow: 0;
			}

			.part0BotContent {
				// margin-left: 10px;
				min-width: 323px;
				height: 120px;
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				text-align: left;
				color: #314455;
				order: 0;
				flex-grow: 0;
				margin-top: 10px;
			}

			.part0BotButton {
				// margin-left: 10px;
				margin-top: 30px;
				width: 155px;
				height: 22px;
				flex-grow: 0;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 10px;
				padding: 10px 16px;
				border-radius: 5px;
				background-color: #062440;
			}

			.part0ButtonText {
				display: flex;
				height: 22px;
				flex-grow: 0;
				font-family: Poppins-Bold;
				font-size: 16px;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.38;
				letter-spacing: normal;
				text-align: center;
				color: #fff;
				white-space: nowrap;
			}

		}
	}

	.part1 {
		margin-top: 40px;
		height: 1150px;

		.part1Total {

			.part1Top {
				height: 100%;
				position: relative;
				margin: 0 26px;
				// display: flex;
				// flex-direction: column;
				// justify-content: flex-start;
				// align-items: center;

				.part1TopTitle {
					width: 323px;
					max-height: 126px;
					font-family: 'Poppins-Bold';
					font-style: normal;
					// font-weight: 700;
					font-size: 36px;
					line-height: 42px;
					color: #062440;
					flex: none;
					order: 0;
					flex-grow: 1;
					text-align: left;
					margin-bottom: 20px;
				}

				.part1TopContent {
					min-width: 323px;
					max-height: 60px;
					font-family: 'Poppins';
					text-align: left;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					color: #314455;
					flex: none;
					order: 0;
					flex-grow: 1;
				}
			}

			.part1Bottom {
				.items {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					flex-wrap: nowrap;
					flex-grow: 0;
					

					.item {
						// display: inline-block;
						// position: relative;
						width: 271px;
						height: 274px;
						margin: 10px 0;
						flex-shrink: 0;
						border: 1px solid #E6EBF2;
						box-sizing: border-box;
						border-radius: 10px;
						z-index: -1;
						.itemPic1{
							
							// position: absolute;
							width: 220px;
							
							width: 150%;
							margin-left: -65px;
							margin-bottom: -80px;
							// height: 100%;
							// top: 20px;
							// bottom: 5px;
							// left: -70px;
							z-index: 9;
							
						}
						
						.itemPic2{
							// position: absolute;
							// width: 220px;
							margin-left: 10px;
							margin-top: 20px;
							margin-bottom: -20px;
							// width: 100%;
							// margin-left: -60px;
							// margin-bottom: -80px;
							// height: 100%;
							// top: 20px;
							// bottom: 5px;
							// left: -70px;
							z-index: 9;
							
						}
						
						.itemPic3{
							
							// position: absolute;
							// width: 220px;
							margin-left: 10px;
							margin-right: 10px;
							margin-top: 20px;
							margin-bottom: -20px;
							
						}
						.itemTitle {
							left: 42px;
							width: 186px;
							height: 22px;
							margin: 0 auto;
							margin-top: 30px;
							font-family: 'Poppins-Bold';
							font-style: normal;
							font-size: 16px;
							line-height: 22px;
							letter-spacing: 0.012em;
							color: #314455;
						}
						.itemContent {
							margin: 0 auto;
							margin-top: 10px;
							font-family: 'Poppins';
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 20px;
							color: #67788C;
							width: 181px;
							height: 40px;
						}
					}


					.topItem {
						margin-top: 20px;
					}
					
					.item1Hover{
						border: 1px solid #1890FF !important;
						box-sizing: border-box;
						box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15) !important;
						border-radius: 10px;
					}
					
					.item2Hover{
						border: 1px solid #1890FF !important;
						box-sizing: border-box;
						box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15) !important;
						border-radius: 10px;
					}
					
					.item3Hover{
						border: 1px solid #1890FF !important;
						box-sizing: border-box;
						box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15) !important;
						border-radius: 10px;
					}
				}
			}
		}
	}

	.part2 {
		height: 2200px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;

		.part2_1 {
			height: 666px;
			width: 100%;
			background: #F8F8F8;

			.part2_1Top {
				.image1 {
					margin-top: 50px;
					margin-left: 36px;
					// margin: 50px 58px 43px 46px;
				}
			}

			.part2_1Bottom {
				margin-top: 23px;
			}

			.rightContent {
				position: relative;
				// display: inline-block;
				// width: 375px;
				margin-left: 25px;
			}

			.p1 {
				width: 375px;
				position: absolute;
				top: 20%;
				text-align: left;

				.pic {
					margin-left: -10px;
					width: 80px;
					height: 80px;
				}

				p {
					position: relative;
					font-family: 'Poppins-Bold';
					font-style: normal;
					font-size: 26px;
					line-height: 32px;
					width: 323px;
					height: 32px;
					font-style: normal;
					line-height: 1.17;
					text-align: left;
					color: #062440;
				}

				.content {
					min-width: 323px;
					height: 40px;
					position: relative;
					font-family: 'Poppins';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					flex-grow: 0;
					font-family: Poppins;
					line-height: 1.5;
					text-align: left;
					color: #314455;
					margin-top: 10px;
				}

				.line {
					margin: 20px 0 20px 10px;
					min-width: 323px;
					height: 1px;
					background: #E6EBF2;
				}

				.perLine {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 10px;
					margin-left: 0px;
					padding: 0px;


					.dog {
						margin: 10px 0;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background: #1890ff;
						display: inline-block;
					}

					.text {
						margin: 10px;
						min-width: 284px;
						height: 18px;
						display: inline-block;
						flex-grow: 0;
						font-family: Poppins;
						font-size: 12px;
						font-weight: 400;
						font-stretch: normal;
						font-style: normal;
						line-height: 18px;
						letter-spacing: normal;
						text-align: left;
						color: #314455;
					}
				}
			}
		}

		.part2_2 {
			height: 718px;
			position: relative;
			width: 100%;

			.part2_2Top {
				position: relative;

				.image2 {
					margin-left: 16px;
					margin-right: 16px;
					// margin-top: 50px;
				}
			}

			.part2_2Bottom {
				position: relative;
				margin-top: 23px;
				width: 100%;
				margin-left: 25px;
				// display: inline-block;

				.circleBack {
					position: absolute;
					z-index: -1;
					// left: 5;
					left: 59px;
					top: 40px;
				}
			}

			.p1 {
				width: 375px;
				position: absolute;
				top: 20%;
				text-align: left;

				.pic {
					margin-left: -10px;
					width: 80px;
					height: 80px;
				}

				p {
					position: relative;
					font-family: 'Poppins-Bold';
					font-style: normal;
					font-size: 26px;
					line-height: 32px;
					width: 323px;
					height: 64px;
					font-style: normal;
					line-height: 1.17;
					text-align: left;
					color: #062440;
				}

				.content {
					width: 323px;
					height: 40px;
					position: relative;
					font-family: 'Poppins';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					flex-grow: 0;
					font-family: Poppins;
					line-height: 1.5;
					text-align: left;
					color: #314455;
					margin-top: 10px;
				}

				.line {
					margin: 20px 0 20px 10px;
					min-width: 323px;
					height: 1px;
					background: #E6EBF2;
				}

				.perLine {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 10px;
					margin-left: 0px;
					padding: 0px;


					.dog {
						margin: 10px 0;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background: #1890ff;
						display: inline-block;
					}

					.text {
						margin: 10px;
						width: 284px;
						height: 18px;
						display: inline-block;
						flex-grow: 0;
						font-family: Poppins;
						font-size: 12px;
						font-weight: 400;
						font-stretch: normal;
						font-style: normal;
						line-height: 18px;
						letter-spacing: normal;
						text-align: left;
						color: #314455;
					}
				}
			}
		}

		.part2_3 {
			height: 816px;
			// height: 100%;
			width: 100%;

			.part2_3Top {
				.image3 {
					margin: 110px 26px -70px 26px;
					// margin-top: 50px;
				}
			}

			.part2_3Bottom {
				margin-top: 23px;
			}

			.rightContent {
				position: relative;
				display: inline-block;
				width: 375px;
				margin-left: 25px;
			}

			.p1 {
				width: 375px;
				// margin: 10px;
				position: absolute;
				top: 20%;
				text-align: left;

				.pic {
					margin-left: -10px;
					width: 80px;
					height: 80px;
				}

				p {
					position: relative;
					font-family: 'Poppins-Bold';
					font-style: normal;
					font-size: 26px;
					line-height: 32px;
					width: 323px;
					height: 64px;
					font-style: normal;
					line-height: 1.17;
					text-align: left;
					color: #062440;
				}

				.content {
					width: 323px;
					height: 40px;
					position: relative;
					font-family: 'Poppins';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					flex-grow: 0;
					font-family: Poppins;
					line-height: 1.5;
					text-align: left;
					color: #314455;
					margin-top: 10px;
				}

				.line {
					margin: 20px 0 20px 10px;
					min-width: 323px;
					height: 1px;
					background: #E6EBF2;
				}

				.perLine {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 10px;
					margin-left: 0px;
					padding: 0px;


					.dog {
						margin: 10px 0;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background: #1890ff;
						display: inline-block;
					}

					.text {
						margin: 10px;
						width: 284px;
						height: 18px;
						display: inline-block;
						flex-grow: 0;
						font-family: Poppins;
						font-size: 12px;
						font-weight: 400;
						font-stretch: normal;
						font-style: normal;
						line-height: 18px;
						letter-spacing: normal;
						text-align: left;
						color: #314455;
					}
				}
			}
		}
	}

	// this.item2Hover = false;
	// this.item3Hover = false;
</style>
